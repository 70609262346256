import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => {
  return (
    <Layout
      next={{ name: "고경아", link: "/고경아" }}
      title={`네오-트라이브 \u200B2020: \u200B사회가 잠시 \u200B멀어졌을 때 
      새롭게 태어나는 \u200B것들에 대하여`}
    >
      <SEO title="서문" />
      <div>
        <p>
          2020년 3월 11일, 세계보건기구WHO는 코로나19 바이러스에 대해 세계적
          대유행, 즉 팬데믹Pandemic을 선언했다. 이에 정부는 감염 확산을 막기
          위해 외출과 모임 자제, 재택근무를 권장하는 등 강도 높은 사회적
          거리두기 운동을 실시하고 있다. “이 시국”을 살아가는 인류는 업무 회의를
          화상 채팅으로 대신하는 것은 기본으로, 게임 속에서 서로의 마을에 놀러가
          수다를 떨고 / 카페에 가지 않고 집에서 만든 달고나 커피를 SNS에
          자랑하며 / 예술의전당에서 관객 없이 진행하는 라이브 연주를 방 안에서
          시청하기도 한다. 직접 대면보다 디스플레이를 통한 간접 접촉을 선호하는
          “언택트 라이프Untact Life” 현상은 예전부터 이미 확산되고 있었으나,
          2020년의 팬데믹 상황과 맞물려 새로운 사회 표준이 되고 있다.
        </p>
        <br />
        <p>
          우리는 사회가 잠시 멀어진 지금 새롭게 태어난 이들을{" "}
          <span
            style={{
              borderBottom: "solid 0.1em #4400CC",
            }}
          >
            네오-트라이브2020
          </span>
          으로 정의한다. 네오-트라이브는 전통적인 부족의 개념과 달리 한 집단에만
          속하지 않는다. 각자는 독립된 물리적 공간에 존재하면서 동시에 여러 개의
          가상 공간을 타인과 공유한다. 각자는 하나의 부족에 속하면서 동시에 가상
          공간에서 타인과 모여 새로운 부족을 이루는 것이다. 이렇게 각자의 부족이
          따로 존재하지만 뭉쳐 새로운 부족이 되기도 하고, 다시 자유롭게 흩어지는
          형태를 우리는 네오-트라이브라 부른다.
        </p>
        <br />
        <p>
          네오-트라이브가 모인 가상의 공간에서 디지털 글꼴인 “폰트”는 이들의
          목소리를 대신한다. 전시에 참여한 17인의 서체 디자이너는
          네오-트라이브의 메시지를 전달하는 메신저로 작용하며, 각 디자이너는
          부족을 대표하는 메시지를 짧은 문구로 표현한다. 개별 문구는 분해되어
          다른 문구와 재조립되면서 새로운 메시지를 만들고, 이를 통해 또다른
          네오-트라이브가 탄생한다.
        </p>
      </div>
    </Layout>
  )
}

export default About
